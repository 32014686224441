import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'redux_data/store';
import { getFirstAndLastDateOfWeek } from 'utils/util'; 

// ----------------------------------------------------------------------

const [start_date , end_date] = getFirstAndLastDateOfWeek();
const initialState = {
  isLoading: false,
  isLoadingDetail: false,
  hasError: null,
  start_date: start_date, 
  end_date: end_date,
  present_datas: [],
  absent_datas: [],
  present_data:null,
  absent_data:null,
};

const slice = createSlice({
  name: 'approve_list',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingDetail(state) {
      state.isLoadingDetail = true;
    },
    // NO ERROR
    noError(state) {
      state.hasError = false;
      state.present_data = null;
      state.absent_data = null;
    },

    // UNSET ERROR
    unsetError(state) {
      state.hasError = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.hasError = JSON.stringify(action.payload);
    },

    // GET PRODUCTS
    setPresentDatas(state, action) {
      state.isLoading = false;
      state.present_datas = action.payload;
      state.hasError = null;
    },
    setAbsentDatas(state, action) {
      state.isLoading = false;
      state.absent_datas = action.payload;
      state.hasError = null;
    },

    // GET PRODUCT
    setPresentData(state, action) {
      state.isLoadingDetail = false;
      state.present_data = action.payload;
      state.hasError = null;
    },
    setAbsentData(state, action) {
      state.isLoadingDetail = false;
      state.absent_data = action.payload;
      state.hasError = null;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//   getCart,
//   addCart,
//   resetCart,
//   onGotoStep,
//   onBackStep,
//   onNextStep,
//   deleteCart,
//   createBilling,
//   applyShipping,
//   applyDiscount,
//   increaseQuantity,
//   decreaseQuantity,
//   sortByProducts,
//   filterProducts,
// } = slice.actions;

// ----------------------------------------------------------------------

export function unsetError() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.unsetError());
  }
}

// ----------------------------------------------------------------------

export function getAllPresent({ data = {} , params = {} } = {data: {} , params: {}}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const endpoint = "/present"
      const state = getState();
      if(!data?.start_date){ data.start_date = state.approve_list.start_date} ;
      if(!data?.end_date){ data.end_date = state.approve_list.end_date} ;

      let endpointURL = `${endpoint}/search`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , data )?.then( resp => resp?.data);
      // console.log("searchData", endpointURL ,  response);
      if(response?.success){
        dispatch(slice.actions.setPresentDatas(response.data));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      console.log(error);
      
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ({ queryParams } = {}) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.startLoading());
//     try {

//       let urlEndpoint = `/present/search` // `/position?distinct=true`
//       if(queryParams){
//         Object.keys(queryParams).forEach((key)=>{
//           if(Array.isArray( queryParams[key] )){
//             queryParams[key].forEach((value)=>{
//               if(urlEndpoint.includes("?")){
//                 urlEndpoint += `&${key}=${value}`
//               }else{
//                 urlEndpoint += `?${key}=${value}`
//               }
//             })
//           }else if(urlEndpoint.includes("?")){
//             urlEndpoint += `&${key}=${queryParams[key]}`
//           }else{
//             urlEndpoint += `?${key}=${queryParams[key]}`
//           }
//         });
//       }
      
//       const response = await axios.post(urlEndpoint);
//       // console.log(response.data.data)
//       if(response?.data?.success){
//         dispatch(slice.actions.setPresentDatas(response.data.data));
//       }else{
//         dispatch(slice.actions.hasError(response?.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.response?.data));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getPresentDetail({queryParams} = {}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoadingDetail());
    try {
      // let urlEndpoint = `/present?checkin=1&checkout=1`
      let urlEndpoint = `/present`
      if(queryParams){
        Object.keys(queryParams).forEach((key)=>{
          if(Array.isArray( queryParams[key] )){
            queryParams[key].forEach((value)=>{
              if(urlEndpoint.includes("?")){
                urlEndpoint += `&${key}=${value}`
              }else{
                urlEndpoint += `?${key}=${value}`
              }
            })
          }else if(urlEndpoint.includes("?")){
            urlEndpoint += `&${key}=${queryParams[key]}`
          }else{
            urlEndpoint += `?${key}=${queryParams[key]}`
          }
        });
      }
      const response = await axios.get(urlEndpoint);
      if(response?.data?.success){
        if(response.data.data?.length > 0)dispatch(slice.actions.setPresentData(response.data.data[0]));
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function present_approve_reject({data}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/present/approve_reject` , data);
      if(response?.data?.success){
        dispatch(slice.actions.noError())
        dispatch( getAllPresent({
          data:{
            "confirm_list" : 1 , 
          },
          // queryParams: {
          //   "include[]":["employee"] , 
          //   "confirm_list" : 1 , 
          // },
        }) )
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export function getAllAbsent({ queryParams } = {}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {

      let urlEndpoint = `/absent` // `/position?distinct=true`
      if(queryParams){
        Object.keys(queryParams).forEach((key)=>{
          if(Array.isArray( queryParams[key] )){
            queryParams[key].forEach((value)=>{
              if(urlEndpoint.includes("?")){
                urlEndpoint += `&${key}=${value}`
              }else{
                urlEndpoint += `?${key}=${value}`
              }
            })
          }else if(urlEndpoint.includes("?")){
            urlEndpoint += `&${key}=${queryParams[key]}`
          }else{
            urlEndpoint += `?${key}=${queryParams[key]}`
          }
        });
      }
      
      const response = await axios.get(urlEndpoint);
      // console.log(response.data.data)
      if(response?.data?.success){
        dispatch(slice.actions.setAbsentDatas(response.data.data));
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.response?.data));
    }
  };
}

// ----------------------------------------------------------------------

export function getAbsentDetail({queryParams} = {}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoadingDetail());
    try {
      let urlEndpoint = `/absent`
      if(queryParams){
        Object.keys(queryParams).forEach((key)=>{
          if(Array.isArray( queryParams[key] )){
            queryParams[key].forEach((value)=>{
              if(urlEndpoint.includes("?")){
                urlEndpoint += `&${key}=${value}`
              }else{
                urlEndpoint += `?${key}=${value}`
              }
            })
          }else if(urlEndpoint.includes("?")){
            urlEndpoint += `&${key}=${queryParams[key]}`
          }else{
            urlEndpoint += `?${key}=${queryParams[key]}`
          }
        });
      }
      const response = await axios.get(urlEndpoint);
      if(response?.data?.success){
        if(response.data.data?.length > 0)dispatch(slice.actions.setAbsentData(response.data.data[0]));
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function absent_approve_reject({id , approve}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/absent/approve_reject` , {id , approve});
      if(response?.data?.success){
        dispatch(slice.actions.noError())
        dispatch(getAllAbsent({queryParams: {"include[]":["employee"] , "confirm_list" : 1 } }))
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setApprovePresentDetail(data) {
  return async (dispatch, getState) => {
    // console.log("setDetail" , data);
    dispatch(slice.actions.startLoadingDetail());
    try {
      dispatch(slice.actions.setPresentData(data))
    } catch (error) {
      console.log(error);
    }
  }
}

export function setApproveAbsentDetail(data) {
  return async (dispatch, getState) => {
    // console.log("setDetail" , data);
    dispatch(slice.actions.startLoadingDetail());
    try {
      dispatch(slice.actions.setAbsentData(data))
    } catch (error) {
      console.log(error);
    }
  }
}