import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#273147',
      light: '#2d3852',
      lighter: '#d3ddf5',
      dark: '#212a3c',
      darker: '#1b2232'
    },
    secondary: {
      main: '#693640',
      light: '#b38f96',
      lighter: '#f5d3d9',
      dark: '#5c2832',
      darker: '#4d1e27'
    },
    info:{
      main: '#1e405e',
      light: '#234a6c',
      lighter: '#27537a',
      dark: '#1a3650',
      darker: '#152d42'
    },
    success:{
      main: '#375c26',
      light: '#3f6a2c',
      lighter: '#487831',
      dark: '#2f4e20',
      darker: '#27401b',
    },
    warning:{
      main: '#b88749',
      light: '#d49b54',
      lighter: '#efb05f',
      dark: '#9c733e',
      darker: '#815f33'
    },
    error:{
      main: '#8a3650',
      light: '#9f3e5c',
      lighter: '#b34668',
      dark: '#752e44',
      darker: '#612638'
    },
    
    text: {
      primary: '#525860',
      secondary: '#a0a0a0',
    },
  },
  spacing: (value) => value * 8, // 8px grid system
});

export {customTheme};
