import { lazy, Suspense } from 'react';
import { 
    Navigate, 
    useRoutes, 
    // useLocation, 
} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthGuard from 'guard/AuthGuard';
import GuestGuard from 'guard/GuestGuard';
import NavBarLayout from 'layout/NavBarLayout';
import MobileBackgroundLayout from 'layout/MobileBackgroundLayout';
import {enabledRoute} from './paths';
import LoadingScreen from 'components/LoadingScreen';

export const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Suspense fallback={<LoadingScreen/>}>
      <Component {...props} />
    </Suspense>
  );
};

function dynamicImport(path) {
  // console.log("dynamicImport",path);
  return import(`../pages${path}`).catch((e) => { console.log('Failed to load component:', e); return ({ default: () => <div/> }); }) 
}

const getNavMenu = () => {
  let navMenu = [];
  const nav = enabledRoute();
  // console.log("enabledRoute",nav);
  
  function loopThroughArrayData(arrayData, parentPath = '') {
    let itemData = []
    for (let i = 0; i < arrayData.length; i++) {
      const obj = arrayData[i];
      const fullPath = `${parentPath}${obj.path}`; // .replace(/^\//, '');
      // console.log("loopThroughArrayData fullPath",fullPath);
      // console.log("obj" , obj);
      
      if(obj.hasOwnProperty("children")){
        if(itemData.length === 0){
          itemData.push({ element: <Navigate to={`${fullPath}`} replace />, index: true })
        }
        // console.log("HAS CHILDREN" , fullPath);
        itemData.push({ 
          path: `${obj.path}`.replace("/","") , 
          fullPath , 
          children : loopThroughArrayData(obj.children , fullPath),
        })
        
      }else{
        console.log("NO CHILDREN" , fullPath);
        const ElementObject = Loadable(lazy(() => dynamicImport(`${fullPath}`) ));
        if(itemData.length === 0){
          itemData.push({ element: <Navigate to={`${fullPath}`} replace />, index: true })
        }
        itemData.push({ 
          path: `${obj.path}`.replace("/","")  , 
          fullPath,
          element : <ElementObject/> 
        })
      }
      
    }
    return itemData;
  }
  
  navMenu = loopThroughArrayData(nav);
  // console.log("getNavMenu",navMenu);
  return navMenu;
};

function cutObjectFromArray({arrayData , key , value}) {
  let removedObject = [];
  // Find the index of the object with `data: 3`
  const index = arrayData.findIndex((item) => item[key] === value);

  // Check if the object was found
  if (index !== -1) {
    // Use splice to remove the object from arrayData and push it to arr2
    removedObject = arrayData.splice(index, 1);
    // returned.push(removedItem);
  }
  return removedObject; 
}

export default function Router() {
  let navMenu = getNavMenu();
  
  let attendanceNavMenu = cutObjectFromArray({arrayData:navMenu , key:"path" , value: "Attendance"})?.[0];
  // console.log(attendanceNavMenu);
  
  
  return useRoutes([
    {
      path: 'Auth',
      children: [
        {
          path: 'Login',
          element: (<GuestGuard>
              <Login />
            </GuestGuard>),
        },
      ],
    },
    // Main Routes
    {
      path: 'Attendance',
      element: (
        <AuthGuard>
          <MobileBackgroundLayout />
        </AuthGuard>
      ),
      children: attendanceNavMenu?.children,
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <NavBarLayout />
        </AuthGuard>
      ),
      children: navMenu,
      // children:[
      //   { path: 'Sales', element: <Sales /> }
      // ]
    },
    {
      path: '*',
      // element: <LogoOnlyLayout />,
      children: [
      //   { path: 'maintenance', element: <Maintenance /> },
        // { path: '*', element: <Page404 /> },
        { path: '*', element:  <Navigate to="/" replace />  },
      ],
    },
  ]);
}
  
  // AUTHENTICATION
  const Login = Loadable(lazy(() => import('pages/Auth/Login')));
  const Page404 = Loadable(lazy(() => import('pages/Page404'))); 

