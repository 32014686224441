export function readFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
}

export function getFirstAndLastDateOfWeek(dateString) {
  if(!dateString){
    dateString = new Date().toISOString().slice(0, 10);
  }
  
  const date = new Date(dateString);
  const dayOfWeek = date.getDay();
  
  let diff = dayOfWeek - 1;
  if(diff < 0){ diff = 6 }

  const firstDateOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - diff, 7);
  const lastDateOfWeek = new Date( firstDateOfWeek );
  lastDateOfWeek.setDate(firstDateOfWeek.getDate() + 6 , 7);
    
  return [firstDateOfWeek.toISOString().slice(0, 10), lastDateOfWeek.toISOString().slice(0, 10)];
}

export function getFirstAndLastDateOfMonth(dateString) { 
  if(!dateString){
    dateString = new Date().toISOString().slice(0, 10);
  }
  const startDate = new Date(dateString);
  startDate.setDate(1); // Set the date to the 1st day of the month

  const endDate = new Date(dateString);
  endDate.setMonth(endDate.getMonth() + 1); // Move to the next month
  endDate.setDate(0); // Set the date to the last day of the previous month
  return [ startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10) ]; // Format as "YYYY-MM-DD"
}

export function formatNumber( number , separator = "," ) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator) || "";
}

export function convertJsonToCsv (jsonData) {
  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };
  
  const flattenJsonArray = (data) => {
    return data.map(item => flattenObject(item));
  };

  const flatData = flattenJsonArray(jsonData);
  const headers = Object.keys(flatData[0]);
  const csvRows = [headers.join(','), ...flatData.map(row => {
    return headers.map(fieldName => {
      let value = row[fieldName];

      // Convert nested arrays or objects to JSON strings
      if (typeof value === 'object' && value !== null) {
        value = JSON.stringify(value);
      }

      // Escape double quotes in the value
      if (typeof value === 'string') {
        value = `"${value.replace(/"/g, '""')}"`;
      }

      return value;
    }).join(',');
  })];
  return csvRows.join('\n');
};

export function downloadCsv (csvContent, fileName) {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  
  if (link.download !== undefined) { // feature detection
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export function formatDateToYYMMDDHHmmss(inputDate) {
  // Check if the input is a number (Unix timestamp)
  const date = typeof inputDate === 'number' ? new Date(inputDate) : new Date(Date.parse(inputDate));

  // Helper function to pad single digits with leading zero
  const padWithZero = (num) => num.toString().padStart(2, '0');

  // Extract parts of the date
  const year = date.getFullYear().toString().slice(-2); // Last two digits of the year
  const month = padWithZero(date.getMonth() + 1); // Months are zero-based, so we add 1
  const day = padWithZero(date.getDate());
  const hours = padWithZero(date.getHours());
  const minutes = padWithZero(date.getMinutes());
  const seconds = padWithZero(date.getSeconds());

  // Concatenate the parts to get the desired format
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}
export function jsonToFormData(obj,  subKeyStr = '' , formData = new FormData(),) {

  function createFormData(obj, subKeyStr) {
    const keys = Object.keys(obj);  // Get all keys in the object
    // console.log("keys obj" , keys);
    
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = obj[key];
      let newKey = subKeyStr ? `${subKeyStr}[${key}]` : key;
      // console.log(newKey , value , typeof(value));
      
      if ( typeof(value) === 'number' ) {
        // console.log(newKey , typeof(value));
        formData.append(newKey, value);
      } else if (typeof(value) === 'string' && value != '') {
        // console.log(newKey , typeof(value));
        formData.append(newKey, value);
      } else if (value instanceof File) {
        // console.log(newKey , typeof(value));
        formData.append(newKey, value); // Handle file uploads
      } else if (Array.isArray(value)) {
        // console.log(newKey , typeof(value));
        for (let j = 0; j < value.length; j++) {
          let arrayKey = `${newKey}[${j}]`;
          if (typeof value[j] === 'object') {
            createFormData(value[j], arrayKey);
          } else {
            formData.append(arrayKey, value[j]);
          }
        }
      } else if (typeof(value) === 'object' ) {
        // console.log(newKey , typeof(value) );
        createFormData(value, newKey);
      }
    }
  }

  createFormData(obj, subKeyStr);
  return formData;
}


export function jsonToFormDatax(data, formData = new FormData(), parentKey = '') {
  // Helper function to check if a value is a plain object
  const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date) && !(value instanceof File);

  if (data === null || data === undefined) return formData;

  const stack = [{ data, parentKey }]; // Stack to keep track of data and its corresponding parent key

  while (stack.length > 0) {
    const { data: currentData, parentKey: currentParentKey } = stack.pop();

    if (isObject(currentData)) {
      // Handle objects (except Date and File)
      Object.keys(currentData).forEach((key) => {
        const value = currentData[key];
        const fullKey = currentParentKey ? `${currentParentKey}[${key}]` : key;

        if (value instanceof Date) {
          formData.append(fullKey, value.toISOString()); // Convert Date to ISO string
        } else if (value instanceof File) {
          formData.append(fullKey, value); // Append File directly
        } else if (Array.isArray(value)) {
          // Handle arrays
          value.forEach((item, index) => {
            if (isObject(item)) {
              stack.push({ data: item, parentKey: `${fullKey}[${index}]` });
            } else {
              formData.append(`${fullKey}[${index}]`, String(item)); // Append each array item
            }
          });
        } else if (isObject(value)) {
          stack.push({ data: value, parentKey: fullKey }); // Push nested object to the stack
        } else {
          formData.append(fullKey, value === null ? '' : String(value)); // Handle null values properly
        }
      });
    } else {
      // Append primitive values
      formData.append(currentParentKey, currentData === null ? '' : String(currentData));
    }
  }

  return formData;
}

